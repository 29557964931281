/* ModeratingPigButchering.css */
.poster-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem auto;
    max-width: 1000px;
  }
  
  .poster-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  