@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

body {
  background-color: #f5f5f5;
  font-family: 'Inter', sans-serif;
  color: #333;
  margin: 0;
  padding: 0;
}

.automatedzone-page {
  max-width: 60%;
  margin: 2rem auto;
  display: flex;
  background-color: #f5f5f5;
  text-align: left;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 1;
}
.automatedzone-page p {
  font-size: .9rem;
  color: #666;
  margin-bottom: 1rem;
  line-height: 1.3;
}
.automatedzone-page ul {
  list-style-type: disc;
  padding-left: 2rem;
  margin-bottom: 1rem;
}
.automatedzone-page ul li {
  margin-bottom: 0.5rem;
  font-size: .9rem;
  color: #666;

}

.zone2 {
  width: 70%;
  height: auto;
  margin-left: 10%;
}

.header-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 2rem 0;
  background-color: #f5f5f5;
}

.header-section h1 {
  font-size: 2.3rem;
  margin-bottom: 1rem;
  color: #333;
  font-family: 'Inter', sans-serif;
  line-height: 1.4;
}

.header-section p {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 0.5rem;
  line-height: 1.4;
  font-family: 'Inter', sans-serif;
}

.section {
  margin-bottom: 2rem;
}

.section h2 {
  font-size: 1.9rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.section h3 {
  font-size: 1.3rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.section h4 {
  font-size: 1.1rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.section p {
  font-size: 1rem;
  margin-bottom: 1rem;
  line-height: 1.4;
  color: rgb(66, 66, 66);
}

.section ul {
  list-style-type: disc;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

.section ul li {
  margin-bottom: 0.5rem;
}

.image-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.image-container img {
  max-width: 70%;
  height: auto;
  border-radius: 8px;
}

.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 100%;
}

.video-container iframe {
  width: 100%;
  height: 40vh;
  border-radius: 8px;
}
