.tool-tile-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-size: cover;
  background-position: center;
  width: 15vw;
  height: 15vw;
  margin: 1.5rem;
  /* border-radius: 12px; */
  overflow: hidden;
  /* border: 1px solid rgb(220, 220, 220);  */
  font-family: 'Inter', sans-serif;
  /* background-color: rgb(202, 202, 202); */
  /* box-shadow: 0 2px 2px rgba(211, 211, 211, 0.2);  */
}


.tool-tile-content {
  text-align: center;
  padding: 0.5rem;
  /* background-color: #e6e6e6; */
  /* border-top: 1px solid rgb(153, 153, 153); */
  color: black;
}

.tool-tile-content h3 {
  margin: 0 0 0.5rem 0;
  font-size: calc(0.5vw + 0.5vh + 0.5vmin);
}

.tool-tile-content p {
  margin: 0.5rem 0;
}

.tool-tile-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 768px) {
  .tool-tile-container {
    width: 50px;
    height: 50px;
  }
}
