/* Work.css */
/* At the top of your CSS file */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

/* Apply Inter font to the entire document */
body {
    font-family: 'Inter', sans-serif;
  }

.work-section {
    padding: 2rem;
    background-color: #F5F5F7;
  }
  
  .work-header {
    text-align: center;
    font-family: 'Inter', sans-serif;
    /* font-size: 2.5rem; */
    font-size: clamp(2rem, 2.5vw, 3rem);
    margin-bottom: 2rem;
    color: #333;
  }
  
  .work {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
  }
