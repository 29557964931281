/* Header.css */

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 20px;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  
  .logo img {
    height: 10vh; /* Adjust the size as needed */
  }
  
  .nav ul {
    list-style: none;
    display: flex;
    gap: 20px;
    padding: 0;
    margin: 0;
  }
  
  .nav ul li {
    margin: 0;
  }
  
  .nav ul li a {
    display: inline-block;
    padding: 10px 20px;
    text-decoration: none;
    color: black;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
  }
  
  .nav ul li a:hover {
    background-color: black;
    color: white;
    border: 1px solid black;
  }
  