@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

body {
  background-color: #f5f5f5;
  font-family: 'Inter', sans-serif;
  color: #333;
  margin: 0;
  padding: 0;
}

.project-page {
  max-width: 60%;
  margin: 2rem auto;
  background-color: #f5f5f5;
  text-align: left;
}

.project-logo {
  width: 40%; /* Adjust the size as needed */
  height: auto;
  margin-left: 30%;
  align-items: center;
}

.web-logo {
  width: 10%; /* Adjust the size as needed */
  align-items: center;
  height: auto;
}

.project-page h1 {
  font-size: 2.3rem;
  margin-bottom: 1rem;
}

.project-page p {
  font-size: .9rem;
  margin-bottom: 1rem;
  line-height: 1.3;
  color: rgb(66, 66, 66);
}

.project-page h2 {
  font-size: 1.9rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.project-page h3 {
  font-size: 1.3rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.section {
  margin-bottom: 2rem;
}

.section img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.prototype-image {
  width: 50%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 1rem;
}
.expo-image {
    width: 20%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 1rem;
  }
.data-image {
    width: 100%;
    height: auto;
    /* border-radius: 8px; */
    margin-bottom: 1rem;
}
.section ul {
  list-style-type: disc;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

.section ul li {
  margin-bottom: 0.5rem;    
}

.section ol {
  list-style-type: decimal;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

.section ol li {
  margin-bottom: 0.5rem;    
}

.section strong {
  color: #1a1a1a;
}

.video-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.video-container iframe {
  width: 80%;
  height: 40vh;
  border-radius: 8px;
}
