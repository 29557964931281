@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

/* About.css */

.about {
  display: flex;
  flex-direction: column;
}

.about-container {
  display: flex;
  justify-content: space-between; /* Space between text and image */
  align-items: flex-start;
  text-align: left;
  background-color: black;
  /* border-radius: 10px; */
  padding: 2rem;
}

.about-text {
  width: 60%;
  color: white;
  font-family: 'Inter', sans-serif;
  padding-right: 2rem;
  padding-left: 1rem;
  
}

.about-text h2 {
  text-align: left;
  margin-bottom: 1.5rem;
  font-size: clamp(2rem, 2vw, 50px); /* Dynamically adjust font size */
}

.about-content p {
  font-size: clamp(14px, 1vw, 14px); /* Dynamically adjust font size */
  line-height: 1.4;
  text-align: left;
  margin-bottom: 1rem;
}

.about-content a {
  color: black;
  text-decoration: none;
}

.about-content a:hover {
  text-decoration: underline;
}

.tools-section {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 3rem;
  /* background-color: blue; */
  /* margin: 5rem;
  background-color: #f5f5f5; */
}
.hexagon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  /* align-items: center; */
  /* background-color: violet; */
  /* width: 90%; */
  padding: 3rem;
  height: auto;
  /* margin: 5rem; */
}

.tools-section h2 {
  font-size: clamp(2rem, 2vw, 50px); /* Dynamically adjust font size */
  margin-bottom: 1rem;
}

.tool-tile-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping to next line if necessary */
  background-color: #F5F5F7;
  border-radius: 1rem;
}

.tools-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Allow wrapping to next line if necessary */
  justify-content: flex-start; /* Align items to the start of the container */
}

.tools h3 {
  font-size: clamp(1.5rem, 2vw, 25px); /* Dynamically adjust font size */
  margin-bottom: 1rem;
}

.about-image {
  width: 35%; /* Takes up one-third of the screen */
  display: flex;
  justify-content: center;
}

.about-image img {
  width: 100%;
  height: auto;
  border-radius: 12px;
}

/* Style for the button */
.button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: black;
  background-color: white;
  text-decoration: none;
  border: 1px solid black;
  border-radius: 10px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.button:hover {
  background-color: black;
  border-color: white;
  color: white;
}

@media (max-width: 768px) {
  .about-container {
    flex-direction: column;
    align-items: center;
    padding-left: 0; /* Remove left padding for small screens */
  }

  .about-text {
    width: 90%; /* Full width for small screens */
    text-align: center; /* Center text for small screens */
    margin-right: 0; /* Remove right margin */
  }

  .about-image {
    width: 90%; /* Full width for small screens */
    margin-top: 1rem;
  }
}
body {
  background-color: #f5f5f5;
  font-family: 'Inter', sans-serif;
  color: #333;
  margin: 0;
  padding: 0;
}
