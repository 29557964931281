@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

body {
  background-color: #f5f5f5;
  font-family: 'Inter', sans-serif;
  color: #333;
  margin: 0;
  padding: 0;
}

.project-page {
  max-width: 60%;
  margin: 2rem auto;
  background-color: #f5f5f5;
  text-align: left;
  color: #565656;
  font-size: .9rem;
  font-family: 'Inter', sans-serif;
}
.project-page ul {
  list-style-type: disc;
  padding-left: 1.6rem;
  margin-bottom: 1rem;
}
.project-page ul li {
  margin-bottom: 0.4rem;
}

.project-page section {
  margin-bottom: 3rem;
}
.section-overview {
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.project-logo {
    width: 40%; /* Adjust the size as needed */
    height: auto;
    border-radius: 8px;
    display: block; /* Ensure the logo is treated as a block element */
    margin: 0 auto; /* Center the logo horizontally */
}


.web-logo {
  width: 10%; /* Adjust the size as needed */
  align-items: center;
  height: auto;
}

.iframe-container iframe{
  width: 100%;
  max-width: 700px;
  height: 40vw;
  min-height: 300px;
  max-height: 500px;
  border-radius: 8px;

}


.project-page h1 {
  font-size: 2.3rem;
  margin-bottom: 1.3rem;
}

.project-page p {
  font-size: .9rem;
  margin-bottom: 1rem;
  line-height: 1.3;
  color: rgb(66, 66, 66);
}

.project-page h2 {
  font-size: 1.9rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.project-page h3 {
  font-size: 1.3rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}
.project-page h4 {
  font-size: 1.1rem;
  margin-top: 2rem;
  margin-bottom: .5rem;
}

.section {
  margin-bottom: 1rem;
}

.section img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.section-systems img {
  width: 80%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.section ul {
  list-style-type: disc;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

.section ul li {
  margin-bottom: 0.5rem;    
}

.section strong {
  color: #1a1a1a;
}

.video-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.video-container iframe {
  width: 80%;
  height: 40vh;
  border-radius: 8px;
}

.prototype-image-1 {
    width: 50%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 1rem;
}


.ignite-embed {
  width: 90%;
  height: 600px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
