@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

/* ProjectTile.css */

.project-tiles-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch; /* Ensure tiles stretch to fill the container */
}

.project-tile-link {
  text-decoration: none; /* Remove underline from the link */
  width: 100%;
  max-width: 400px; /* Ensure the tiles have a fixed size */
  height: 100%;
  max-height: 500px; /* Ensure the tiles have a fixed size */
}

.project-tile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  margin: 0.5rem;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-family: 'Inter', sans-serif;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add transition for hover effect */
}

.project-tile:hover {
  transform: scale(1.05); /* Scale up the tile slightly */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Enhance the shadow to give a lift effect */
}

.special-tile {
  background: linear-gradient(to bottom, #dad9cf, #ffffff); /* Change colors as needed */
  /* background-blend-mode: overlay; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add box shadow */
  transition: box-shadow 0.3s ease-in-out; /* Add transition for hover effect */
}

.special-tile:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Increase shadow on hover */
}

.special-tile2 {
  background: linear-gradient(to bottom, #739bcc, #ffffff); /* Change colors as needed */
  /* background-blend-mode: overlay; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add box shadow */
  transition: box-shadow 0.3s ease-in-out; /* Add transition for hover effect */
}

.special-tile2:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Increase shadow on hover */
}

.tile-content {
  /* position: absolute; */
  /* top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left; */
  z-index: 2;

  text-align: left;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  color: black;
  padding: 1.5rem;
  height: 5rem;
  font-size: calc(0.5vw + 0.5vh + 0.25vmin); /* Smaller dynamic font size */
  /* position: absolute; */

}

.tile-content h5 {
  margin: 0 0 0.25rem 0;
  font-size: calc(0.5vw + 0.5vh + 0.25vmin); /* Dynamic font size for type */
  color: #777;
}

.tile-content h3 {
  margin: 0 0 0.5rem 0;
  font-size: calc(1vw + 1vh + 0.5vmin); /* Smaller dynamic font size for headers */
}

.tile-content p {
  margin: 0.5rem 0;
  font-size: calc(0.5vw + 0.5vh + 0.25vmin); /* Smaller dynamic font size for paragraphs */
}

.tile-price {
  font-size: calc(0.5vw + 0.5vh + 0.25vmin); /* Smaller dynamic font size for prices */
  color: #ccc;
}

.tile-date {
  font-size: calc(0.5vw + 0.5vh + 0.25vmin); /* Smaller dynamic font size for dates */
  color: #777;
}

.tile-image {
  width: 100%;
  height: auto; /* Adjust the height dynamically */
  object-fit: contain; /* Ensure the image covers the entire area */
  object-position: center; /* Center the image within its container */
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
  overflow: hidden;
  z-index: 1;
  /* position: relative; */
}

@media (max-width: 768px) {
  .project-tile {
    width: calc(50% - 1rem); /* Adjust for smaller screens */
    height: calc(50% - 1rem); /* Adjust for smaller screens */
    flex-direction: column;
  }

  .tile-image {
    width: 100%;
    height: auto;
    border-radius: 0 0 12px 12px;
  }
}

@media (max-width: 480px) {
  .project-tile {
    width: calc(100% - 1rem); /* Adjust for very small screens */
    height: calc(100% - 1rem); /* Adjust for very small screens */
  }
}
