/* CartaV2.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

body {
  background-color: #f5f5f5;
  font-family: 'Inter', sans-serif;
  color: #333;
  margin: 0;
  padding: 0;
}

.carta-page {
  max-width: 60%;
  margin: 2rem auto;
  background-color: #f5f5f5;
  text-align: left;
}

.project-logo {
  width: 40%; /* Adjust the size as needed */
  height: auto;
  margin-left: 30%;
  align-items: center;
}

.web-logo {
  width: 10%; /* Adjust the size as needed */
  align-items: center;
  height: auto;
}

.carta-page h1 {
  font-size: 2.3rem;
  margin-bottom: 1rem;
}

.carta-page p {
  font-size: 0.9rem;
  margin-bottom: 1rem;
  line-height: 1.3;
  color: rgb(66, 66, 66);
}

.carta-page h2 {
  font-size: 1.9rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.carta-page h3 {
  font-size: 1.3rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}
/* 
.carta-page ul {
  list-style-type: disc;
  padding-left: 1rem;
  margin-bottom: 1rem;
}
.carta-page ul li {
  color: #383737;
  margin-bottom: 0.5rem;
} */

.section {
  margin-bottom: 2rem;
}

.section img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.section ul {
  list-style-type: disc;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

.section ul li {
  margin-bottom: 0.5rem;
}

.carta-page ul li {
  color: #383737;
  margin-bottom: 0.5rem;
  margin-top: 0.4rem;
}

.section strong {
  color: #1a1a1a;
}

.iframe-container {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.carta-embed {
  width: 90%;
  height: 600px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
