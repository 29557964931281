/* BlogTile.css */

.blog-tile {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .blog-tile-image {
    width: 100%;
    height: auto;
  }
  
  .blog-tile-content {
    padding: 1rem;
  }
  
  .blog-tile-content h3 {
    margin: 0 0 0.5rem 0;
    font-size: 1.25rem;
  }
  
  .blog-tile-content p {
    margin: 0;
    color: #555;
  }
  